import EnrollForm from "/src/components/enrollForm"
import Layout from "/src/components/layout"

import "/src/styles/oneOne.css"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function OneOne() {
  return (
    <>
      <Layout route="/courses">
        <div id="oneOnePage">
          <div className="grid grid-rows-1 hero">
            <div>
              {" "}
              <h1>ONE-TO-ONE COACHING</h1>
            </div>
          </div>
          <div className="grid grid-rows-1 genInfoRow">
            <div className="grid grid-cols-12 ">
            <div className="col-start-2 col-span-10 md:col-start-2 md:ml-8 md:col-span-4 genInfo ">
                GENERAL
                <br className="hidden md:block" /> INFO
              </div>
              <div className="col-start-2 col-span-10 md:col-span-6 md:col-start-6 genText ">
                <p>
                  Enroll for getting one on one specialized training from our
                  expert yoga trainers.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 instructorRow">
            <div className="grid grid-cols-12 ">
              <div className="col-start-2 col-span-10 md:col-span-2 md:col-start-6 mb-7 insHeadMob block lg:hidden">
                MEET YOUR TRAINERS
              </div>

             
              <div className="col-start-2 col-span-10 md:col-span-10 md:col-start-2 ">
                <div className="grid grid-rows-1 ">
                  <div className=" grid grid-cols-12">
                    <div className="col-start-2 col-span-10 md:col-span-10 md:col-start-1 insHead hidden lg:block mb-9 ml-8 ">
                      MEET YOUR TRAINERS
                    </div>
                    {/* Instructor1  */}
                    <div className="col-start-2 col-span-10 md:col-start-1 md:col-span-3 md:ml-8 insImg insText">
                      {/* <img src={Instructor} alt="instructor" /> */}

                      <StaticImage
                        src="../../images/meetInstructor.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Dr Anjana Pattali</b>
                        <br />
                        BAMS, Msc Yoga Therapy, <br />
                        Director , Antaryami
                        <br />
                      </p>
                    </div>
                    {/* Instructor2  */}
                    <div className="col-start-2 col-span-10 md:col-start-4 md:col-span-3 md:ml-8 insImg insText">
                      {/* <img src={Instructor1} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/sruthi.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Sruthi A</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                    {/* Instructor3  */}
                    <div className="col-start-2 col-span-10 md:col-start-7 md:ml-8 md:col-span-3 insImg insText">
                      {/* <img src={Instructor2} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/shivapriya.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Shivapriya K</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                    {/* Instructor4  */}
                    <div className="col-start-2 col-span-10 md:col-start-10 md:ml-8 md:col-span-3 insImg insText">
                      {/* <img src={Instructor3} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/akhila.jpeg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Akhila C K</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EnrollForm category="One to One coaching Enrollment" />
        </div>
      </Layout>
    </>
  )
}

export default OneOne
